.cmsplugin-latest-articles {
    .column-title {
        border-bottom: 5px solid @brand-primary;

        h3 {
            text-transform: uppercase;
        }
    }

    .single-post {
        margin-top: 15px;

        .title {
            color: #000;
            font-weight: bold;
        }

        .text a {
            color: @brand-info;
        }
    }

    .read-all-wrap {
        margin-top: 15px;

        a {
            color: @brand-info;
        }
    }
}

.for-consumers {
    .breadcrumbs {
        margin-top: 15px;
    }
    .consumer-links {
        padding: 0;
        .consumer-link {
            padding: 0;
            margin-bottom: 10px;
            background-color: @gray-lighter;
            &:hover {
                .fa {
                    background-color: @brand-warning;
                    &:after {
                        left: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        border-color: rgba(136, 183, 213, 0);
                        border-left-color: @brand-warning;
                        border-width: 9px;
                        margin-top: -8px;

                    }
                }
            }
            .fa {
                position: relative;
                background-color: @brand-primary;
                color: white;
                font-size: @font-size-huge;
                min-width: 60px;
                min-height: 50px;
                padding: 10px;
            }
            a {
                .text-uppercase();
                float: right;
                font-weight: bold;
                margin: 18px;
            }
        }
    }
    /* This is copied from .newsletter-description in frontpage.less
     * They could probably have a shared class instead
     */
    .leave-classified {
        padding: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0;
        margin-right: 0;
        border-left: 15px solid @brand-info;
        background-color: @brand-primary;
        color: white;
        @media (min-width: @screen-md-min) {
            // center the texts vertically
            display: flex;
            justify-content: center;
            .leave-classified-texts {
                align-self: center;
            }
        }

        // bring the texts together
        p {
            margin: 0;
        }
        a {
            .text-uppercase();
            color: white;
        }
        // this is the button to the right
        .navigation-shortcut {
            font-size: @font-size-large;
            font-weight: bold;
            text-align: center;
            margin-top: 4px;
            height: 50px;
            background-color: @brand-warning;
            border-radius: 2px;
            .texts {
                padding-top: 12px;
                padding-left: 10px;
            }
            .arrow {
                position: relative;
                top: -14px;
                float: right;
                margin-right: 10px;
                font-size: @font-size-stupendous;
            }
            div {
                display: inline-block;
            }
        }
    }
}

.bootstrap-tagsinput {
    display: block;

    .twitter-typeahead {
        display: inline;

        .tt-menu, .tt-dropdown-menu {
            &:extend(.dropdown-menu);
        }

        .tt-suggestion {
            &:extend(.dropdown-menu > li > a);

            &:hover, &:focus {
                &:extend(.dropdown-menu > .active > a);
            }
        }

        .tt-suggestion.tt-cursor {
            &:extend(.dropdown-menu > .active > a);
        }
    }
}

//
// COMMON STYLES FOR ALL ARTICLE TYPES
// 

.post-list {
    .single-post {
        margin-bottom: 45px;
    }

    .post-image-wrap {
        .make-md-column(4);

        img {
            height: auto;
            max-width: 100%;
        }
    }

    .post-summary-wrap {
        .make-md-column(8);
    }

    h2, h4 {
        margin: 15px 0;
        word-wrap: break-word;

        a {
            color: black;
        }
    }
}

.post-metadata {
    color: @gray-light;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 600;
    margin-bottom: 15px;
}

.read-post-btn,
.more-articles-btn {
    border: 1px solid @border-darkened;
    color: @brand-success;
    display: block;
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 25px;
    padding: 15px 0;
    position: relative;
    text-align: center;
    width: 100%;

    &:hover,
    &:focus {
        background: @gray-lighter;
        border-color: @border-darkened;

        i {
            color: @gray;
        }
    }

    i {
        color: @border-darkened;
        font-size: 2em;
        position: absolute;
        right: 15px;
        top: 12px;
    }
}

//
// ARTICLES SPECIFIC STYLING
//
body.articles {
    .article-content {
        .make-md-column(8);

        .article-title {
            word-wrap: break-word;

            @media (min-width: @screen-md-min) {
                margin-top: 0;
            }
        }

        .article-text {
            word-wrap: break-word;
        }

        h1 {
            font-size: 28px;
        }

        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 20px;
        }
    }

    .article-sidebar {
        .make-md-column(4);

        .article-sidebar-image {
            img {
                height: auto;
                margin: 0 auto;
                width: 80%;
            }
        }

        .banner {
            .size(290px; 290px);
            margin-bottom: 15px;

            img {
                .size(auto; auto);
                max-height: 290px;
                max-width: 290px;
            }
        }
    }
}

.article-preview {
    ins, del {
        color: white;
        padding: 0.2em;
        text-decoration: none;
    }

    ins {
        background-color: green;

        iframe {
            border: 10px solid green;
        }
    }

    del {
        background-color: red;

        iframe {
            border: 10px solid red;
        }
    }
}

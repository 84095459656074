.cmsplugin-article-list {
    .title {
        background-color: @brand-primary;
        color: #fff;
        display: block;
        font-size: @font-size-base;
        line-height: 35px;
        margin-top: 0;
        padding-left: 35px;
    }

    .article-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .article-list-item {
        border-bottom: 1px solid @gray-lighter;
        padding: (@font-size-base / 2) 0;

        a {
            color: #000;
            text-decoration: none;

            .read-more {
                color: @brand-primary;
            }
        }
    }
}

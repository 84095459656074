.cmsplugin-infinite-article-list {
    .article {
        background-color: @light-gray-bg;
    }

    .btn-more-articles {
        background-color: @light-gray-bg;
        border: none;
        color: @brand-primary;

        &:hover {
            background-color: darken(@light-gray-bg, 5%);
        }
    }
}

.cmsplugin-article-list {
    margin-top: 10px;

    .label-primary {
        background-color: @brand-danger;
    }
}

.cms-page {
    .page-content {
        img {
            // The "!important" is here because CKEditor uses inline styles to
            // define image height and width, and we want to override that.
            height: auto !important;
            max-width: 100%;
        }
    }
}

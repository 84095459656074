.cmsplugin-infinite-article-list {
    .title {
        background: @brand-primary;
        color: #fff;
        padding: 10px;
        margin-top: 10px;
    }

    .article-list {
        border-right: 1px solid @border-color;
    }

    .article {
        .make-row();
        margin: 10px 0 0 0;

        .image-container {
            .make-md-column(8);
            margin: 0;
            min-height: 329px;
            padding: 0;
            position: relative;

            .image {
                width: 100%;
                max-width: 100%;
            }

            .title-container {
                background: fade(@brand-primary, 85%);
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                z-index: 10;

                .title {
                    color: #fff;
                    margin: 0;
                    padding: 10px;
                }
            }
        }

        .summary-container {
            .make-md-column(4);

            .theme {
                color: @brand-primary;
                font-weight: bold;
                padding: 10px 0;
                text-transform: uppercase;
            }

            .summary {
                font-size: larger;

                &:before {
                    content: "\201c";
                }
            }

            .link {
                font-size: larger;
            }
        }
    }

    .btn-more-articles {
        margin: 10px 0 10px 0;
    }
}

.cmsplugin-article-carousel {
    .carousel-overlay-wrap {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 10;

        h3 {
            background: fade(@gray, 85%);
            color: #fff;
            margin: 0;
            padding: 20px 25px;
            position: relative;
            text-decoration: none;

            @media (max-width: @screen-xs-max) {
                font-size: 1.4rem;
                font-weight: normal;
                padding: 10px;
            }
        }
    }

    .carousel-control {
        .arrow {
            display: inline-block;
            height: 100px;
            width: 35px;
            background: transparent;
            position: relative;
            margin: 0 20px;

            &:before, &:after {
                content: "";
                position: absolute;
                left: 50%;
                display: block;
                background: @brand-primary;
                width: 50px;
                height: 7px;
                border-radius: 7px;
                transform-origin: 0 0;
                outline: 1px solid transparent;
            }

            &:before {
                top: 30px;
            }

            &:after {
                bottom: 30px;
            }

            &.left {
                &:before {
                    transform: rotate(-48deg) translate(-50%, -50%);
                }

                &:after {
                    transform: rotate(48deg) translate(-50%, -50%);
                }
            }

            &.right {
                &:before {
                    transform: rotate(48deg) translate(-50%, -50%);
                }

                &:after {
                    transform: rotate(-48deg) translate(-50%, -50%);
                }
            }
        }
    }

    .bg-icon {
        @media (max-width: @screen-xs-max) {
            background-position: 50% 25%;
        }
    }

    .item {
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

body.magazines {
    .magazine-column {
        .make-md-column(4);
        p {
            color: @brand-warning;
        }
    }

    .magazine-hero {
        img {
            display: block;
            max-width: 100%;
        }
    }

    .show-all-btn {
        .btn;
        background: @brand-primary;
        border: 0;
        border-radius: 0;
        color: #fff;
        display: block;
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 15px;
        padding-bottom: 12px;
        padding-top: 12px;
        position: relative;
        width: 100%;

        &:hover, &:focus {
            background: lighten(@brand-primary, 5%);
            color: #fff;
        }

        i {
            font-size: 2rem;
            position: absolute;
            right: 15px;
            top: 11px;
        }
    }

    // This is modified copypasta from frontpage.less
    .email-signup {
        padding-right: 10px;
        margin: 15px 0;
        border-left: 15px solid @brand-info;
        background-color: @gray-lighter;
        color: @brand-warning;
        @media (min-width: @screen-md-min) {
            // center the texts vertically
            display: flex;
            justify-content: center;
            .email-signup-texts {
                align-self: center;
            }
        }

        p {
            margin: 15px;
            font-weight: bold;
            color: @brand-warning;
            font-size: @font-size-large;
        }
        a {
            .text-uppercase();
            color: white;
        }
        // this is the button to the right
        .navigation-shortcut {
            background: url("@{static-path}img/kauppasuomi/thumb.svg") no-repeat;
            background-size: 20px;
            background-position: 18px;
            font-size: @font-size-large;
            font-weight: bold;
            text-align: center;
            margin-top: 25px;
            height: 50px;
            background-color: @brand-primary;
            border-radius: 2px;
            .texts {
                padding-top: 12px;
                padding-left: 30px;
            }
            .arrow {
                position: relative;
                top: -14px;
                float: right;
                margin-right: 10px;
                font-size: @font-size-stupendous;
            }
            div {
                display: inline-block;
            }
        }
    }
}

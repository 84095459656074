.collapsible-menu {
    &.collapse {
        @media (min-width: @screen-md-min) {
            display: block !important;
            height: auto !important;
        }
    }
}

.navigation-top {
    .clearfix;
    background: @light-gray-bg;

    .logo {
        padding: 20px;
        display: inline-block;
        img {
            height: 50px;
        }
    }

    .mobile-nav-toggle {
        float: right;
        display: block;
        position: relative;
        background: none;
        border: 0;
        padding: 0;
        margin: 25px;
        width: 50px;
        height: 36px;
        @media (min-width: @screen-sm-min) {
            height: 50px;
            width: 60px;
        }

        @media (min-width: @screen-md-min) {
            display: none;
        }

        &:focus, &:active {
            outline: 0;
        }

        span {
            left: 12px;
            margin-top: -3px;
        }

        span, span:before, span:after {
            background: @brand-primary;
            border-radius: 1px;
            width: 36px;
            height: 5px;
            position: absolute;
            display: block;
            content: "";
            cursor: pointer;
            transition: all 0.1s ease-in-out;

        }
        span:before {
            top: -10px;
            left: 0;
        }
        span:after {
            bottom: -10px;
            left: 0;
        }

        &.active span {
            background-color: transparent;
            &:before, &:after {
                top: 0;
            }
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
        }
    }
}

.navigation-pages {
    .clearfix;
    background: @brand-primary;

    @media (min-width: @screen-md-min) {
        display: flex;
        flex-flow: row nowrap;
    }

    ul.left-nav {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.2em;

        li {
            height: 50px;
            position: relative;

            @media (min-width: @screen-md-min) {
                display: inline-block;
            }

            &:hover {
                background-color: @brand-info;
            }

            @media (min-width: @screen-md-min) {
                &:hover:after {
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:hover:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-top-color: @brand-info;
                    border-width: 10px;
                    margin-left: -10px;
                }
            }

        }

        a {
            text-decoration: none;
            color: white;
            display: block;
            padding: 12px 15px;
        }

        ul {
            // Hide navigation child items
            display: none;
        }
    }

    .right-nav {
        padding: 0;
        li {
            list-style: none;
        }
        @media (max-width: @screen-sm-max) {
            float: left;
            padding-left: 15px;
            form {
                box-shadow: none;
            }
        }
        @media (min-width: @screen-md) and (max-width: @screen-md-max) {
            padding: 0;
        }
        @media (min-width: @screen-md) {
            float: right;
            margin: 0 0 0 auto;
        }
    }
}

.content-header {
    background: @gray-lighter;
    color: @brand-primary;
    padding: 15px 20px;
    margin: 0;

    &-title {
        color: @brand-primary;
        line-height: 52px;

        h1, h2 {
            color: @brand-primary;
            display: inline-block;
            font-family: Salsa, cursive;
            font-size: 28px;
            font-weight: 400;
            margin: 0;
            vertical-align: middle;
        }

        i {
            display: inline-block;
            font-size: 28px;
            margin-left: 15px;
            vertical-align: middle;
        }
    }

    &-additions {
        line-height: 52px;
    }
}

// Separate header from content on index page.
body.index .main-navigation {
    margin-bottom: 10px;
}

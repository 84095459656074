.cmsplugin-contact-form {
    background: @light-gray-bg;

    h1 {
        background: @brand-primary;
        color: #fff;
        font-family: "Salsa", cursive;
        font-weight: 400;
        display: block;
        margin: 0;
        padding: 1em;
        width: 100%;
    }

    form {
        margin: 1em auto;
        width: 95%;
    }
}

.cmsplugin-frontpage-icon {
    .single-icon {
        .make-lg-column(12);
        display: block;
        border-bottom: 1px solid @border-color;
        padding: 15px 0;

        .content-wrap {
            display: table;
            width: 100%;

            .icon-wrap,
            .icon-text {
                display: table-cell;
                vertical-align: middle;
            }

            .icon-wrap {
                width: 60px;

                .icon {
                    background-color: @brand-primary;
                    border-radius: 50%;
                    color: #fff;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    width: 60px;

                    .fa {
                        font-size: 32px;
                        vertical-align: middle;

                        &.fa-envelope {
                            margin-top: -4px;
                        }
                    }
                }
            }

            .icon-text {
                padding-left: 15px;
                text-transform: uppercase;
            }
        }

        &.highlight {
            .content-wrap {
                .icon-wrap {
                    .icon {
                        background-color: @brand-danger;
                    }
                }

                .icon-text {
                    color: @brand-danger;
                }
            }
        }
    }
}

.carousel {
    height: 100%;

    .carousel-control {
        width: 50px;

        @media (min-width: @screen-sm-min) {
            width: 75px;
        }
    }

    .carousel-control.left,
    .carousel-control.right {
        background-image: none;
        display: flex;
        z-index: 3;

        > span {
            align-self: center;
            flex: auto;
        }
    }

    .bg-icon {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 50%;
        display: block;
        padding-top: 100%;
        width: 100%;

        @media (min-width: @screen-sm-min) {
            background-size: auto;
        }

        &.white-arrow-left {
            background-image: url(../img/white-arrow-left.png);
        }

        &.white-arrow-right {
            background-image: url(../img/white-arrow-right.png);
        }
    }

    &.fade {
        opacity: 1;

        .item {
            .size(100%; 100%);
            display: block !important;
            left: 0 !important;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 0.6s ease-in-out;
            z-index: 1;

            &:first-child {
                position: relative;
                top: auto;
            }

            &.active {
                height: 100%;
                opacity: 1;
                transition: opacity 1s ease-in-out;
                z-index: 2;
            }
        }
    }

    .carousel-indicators {
        bottom: auto;
        left: auto;
        margin: 0;
        right: 13px;
        top: 10px;
        width: auto;

        li {
            .size(18px; 18px);
            background: #fff;
            border: 2px solid @gray;
            margin: 0 1px;

            &.active {
                .size(18px; 18px);
                background: @brand-primary;
                margin: 0 1px;
            }
        }
    }
}

.owl-carousel.banners {
    .single-slide {
        .size(290px; 290px);

        img {
            display: block;
            height: auto;
            max-height: 290px;
            max-width: 290px;
        }
    }

    .owl-nav {
        left: 0;
        position: absolute;
        top: 50%;
        width: 100%;

        .owl-prev {
            float: left;
            margin-left: -30px;
        }

        .owl-next {
            float: right;
            margin-right: -30px;
        }

        i {
            color: @gray-light;
            font-size: 4rem;
        }

        .disabled > i {
            color: @gray-lighter;
        }
    }
}

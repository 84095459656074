body.classifieds-list {
    .classified-type-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;

        li {
            a {
                background-color: @brand-primary;
                color: #fff;
                display: block;
                padding: 1em;
                text-decoration: none;
            }

            a:hover {
                background-color: @brand-info;
            }

            &.active {
                a {
                    background-color: @brand-danger;
                    position: relative;

                    @media (min-width: @screen-md-min) {
                        &:after {
                            border: 10px solid transparent;
                            border-top-color: @brand-danger;
                            content: " ";
                            height: 0;
                            left: 50%;
                            margin-left: -10px;
                            pointer-events: none;
                            position: absolute;
                            top: 100%;
                            width: 0;
                        }
                    }
                }
            }
        }
    }

    .classified-ad {
        a {
            color: @text-color;
        }

        dl {
            dt {
                display: inline;
                font-weight: normal;
            }

            dd {
                display: inline;

                &::after {
                    content: "";
                    display: block;
                }
            }
        }

        .classified-ad-images .item img {
            display: block;
            height: auto;
            width: 100%;
        }

        .default-image {
            background: darken(@light-gray-bg, 5%);
            padding: 25px 50px;
        }
    }
}

body.classified-ad-detail {
    .classified-ad {
        dl {
            dt {
                display: inline;
                font-weight: normal;
            }

            dd {
                display: inline;

                &::after {
                    content: "";
                    display: block;
                }
            }
        }

        .lightbox-images {
            list-style: none;
            padding: 0;

            li {
                display: inline-block;
                margin: 0.625em;

                img {
                    max-height: 200px;
                    max-width: 200px;
                }
            }
        }
    }
}


.classifieds-form {
    .form-container {
        background: @light-gray-bg;
        padding-left: 0;
        padding-right: 0;
        width: 80%;

        h1 {
            background: @brand-primary;
            color: #fff;
            font-family: Salsa, cursive;
            font-weight: 400;
            display: block;
            margin: 0;
            padding: 1em;
            width: 100%;
        }

        form {
            margin: 0 auto;
            width: 85%;
        }
    }
}

.well.classified-ad-preview {
    .text {
        font-family: Consolas, "Lucida Grande", monospace;
    }
}

.front-page {
    .make-row();
    margin-top: 50px;

    h1 {
        font-family: Salsa, cursive;
        color: @brand-info;
        font-weight: normal;
        .first-word {
            color: @brand-primary;
        }
    }

    .introtext {
        font-size: @font-size-large;
    }

    .circulation-region-plugin {
        .text-uppercase();
        font-family: Salsa, cursive;
        background: url("@{static-path}img/kauppasuomi/circulation_map.svg") no-repeat;
        background-position: right;
        background-size: contain;

        .title {
            font-size: @font-size-stupendous;
            color: @brand-primary;
        }
        .subtitle {
            font-size: @font-size-huge;
        }

        .region {
            .name {
                font-family: @font-family-sans-serif;
                font-size: @font-size-large;
                position:relative;
                top: 18px;
            }
            .amount {
                font-size: @font-size-stupendous;
            }
        }

        #region-1 span.number {
            color: @brand-info;
        }
        #region-2 span.number {
            color: @brand-primary;
        }
        #region-3 span.number {
            color: @brand-warning;
        }
    }

    .navigation-shortcuts {
        padding-left: 0;
        .navigation-shortcut {
            .clearfix();
            padding: 23px;
            margin: 27px 0 27px 0;
            background-color: @gray-lighter;
            border-left: 15px solid @brand-primary;
            font-family: Salsa, cursive;
            .texts {
                .make-xs-column(6);
            }
            .title, .subtitle {
                font-size: @font-size-h1;
            }
            .subtitle {
                color: @brand-warning;
            }
            .arrow {
                float: right;
                width: 23px;
                height: 23px;
                margin: 26px;
                border-right: 4px solid @brand-info;
                border-top: 4px solid @brand-info;
                transform: rotate(45deg);
                i {
                    display: none;
                }
            }
        }
    }

    // This has been copied in for_consumers.less as .leave-classified
    // and in magazines.less as .email-signup
    .newsletter-description {
        padding: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0;
        margin-right: 0;
        border-left: 15px solid @brand-info;
        background-color: @brand-primary;
        color: white;
        @media (min-width: @screen-md-min) {
            // center the texts vertically
            display: flex;
            justify-content: center;
            .newsletter-description-texts {
                align-self: center;
            }
        }

        // bring the texts together
        p {
            margin: 0;
        }
        a {
            .text-uppercase();
            color: white;
        }
        // this is the button to the right
        .navigation-shortcut {
            background: url("@{static-path}img/kauppasuomi/thumb.svg") no-repeat;
            background-size: 20px;
            background-position: 18px;
            font-size: @font-size-large;
            font-weight: bold;
            text-align: center;
            margin-top: 20px;
            height: 50px;
            background-color: @brand-warning;
            border-radius: 2px;
            .texts {
                padding-top: 12px;
                padding-left: 30px;
            }
            .arrow {
                position: relative;
                top: -14px;
                float: right;
                margin-right: 10px;
                font-size: @font-size-stupendous;
            }
            div {
                display: inline-block;
            }
        }
    }
}

footer {
    .make-row();
    color: #fff;
    padding-top: 30px;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    background-color: @brand-primary;

    .logo {
        &:extend(.img-responsive);
    }

    .text {
        margin: 15px 0 15px 0;
        font-size: @font-size-small;
        .lead {
            font-size: @font-size-small;
            font-weight: bold;
        }
    }

    ul {
        color: #fff;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a, a:hover {
        color: #fff;
        text-decoration: none;
    }

    .links {
        ul {
            border-top: 1px solid #fff;
            margin-top: 15px;
        }

        li {
            border-bottom: 1px solid #fff;
            padding: 5px 0;
        }
    }

    .copyright {
        .make-md-column(12);
        background-color: darken(@brand-primary, 15%);
        font-weight: bold;
        text-align: right;
        padding: 10px 50px 10px 0;
        font-size: @font-size-small;
    }

    .socialmedia-icon-plugin {
        border-top: 0;
        margin-top: 43px;

        li {
            .size(36px; 36px);
            background: #fff;
            border-bottom: 0;
            display: inline-block;
            line-height: 36px;
            margin: 0 5px 5px 0;
            padding: 0;
            text-align: center;
            vertical-align: middle;

            i {
                color: @brand-primary;
                font-size: 24px;
                vertical-align: middle;
            }
        }
    }

    .contacts {
        font-size: @font-size-small;

        .contact-list-plugin {
            dt {
                font-size: @font-size-base;
                font-weight: bold;
                margin-bottom: 10px;
                margin-top: 43px;
            }

            ul {
                border-top: 0;
            }

            li {
                border-bottom: 0;
                padding: 0;
            }

            /*
             * Note: This is a somewhat hacky way to get the contacts being
             * rendered correctly.
             */
            @media (min-width: @screen-md-min) {
                min-height: 130px;
            }
        }
    }
}

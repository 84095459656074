.for-advertisers {
    .intro {
        min-height: 400px;
        .image-holder {
            img {
                max-width: 100%;
            }
        }
    }

    .circulation-region-wrapper:not(:hover) {
        .default-region-name {
            transition: color 0.5s ease;
            color: @brand-danger;
        }
    }

    .circulation-region-link, .circulation-region-link:hover {
        text-decoration: none;
    }

    .circulation-summary {
        display: none;
        padding-top: 30px;
        cursor: default;
        @media(min-width: @screen-md-min) {
            display: block;
        }
        .header {
            color: @gray-lighter;
            font-size: @font-size-huge;
        }
        .region-name {
            font-size: @font-size-huge;
            font-weight: bold;
            transition: color 0.5s ease;
            color: @gray;
        }
        .amount {
            font-size: @font-size-huge;
            color: @brand-info;
            font-weight: bold;
        }
        .image {
            display: none;
            position: absolute;
            left: -75%;
            top: 0%;
            height: 100%;
        }
        .default-image {
            display: block;
        }
        .circulation-region:hover {
            .image {
                display: block;
            }
            .default-image {
                display: none;
            }
            .region-name {
                transition: color 0.5s ease;
                color: @brand-danger;
            }
        }
    }

    .contact-buttons {
        text-align: center;
        font-size: 20px;
        @media (min-width: @screen-sm-min) {
            font-size: 30px;
        }
        a {
            text-decoration: none;
        }
    }

    .contact-button {
        color: @gray-lighter;
        padding: 20px;
        background-color: @contact-button-color;
        border: 1px solid @contact-button-border;
        border-radius: 15px;
    }

    .contact-button-wrapper {
        margin-top: 30px;
    }

    .region-link {
        text-decoration: none;
        height: 70px;
        padding: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
        display: block;
        text-align: center;
        font-size: 19px;
        border-radius: 20px;
        background-color: @brand-primary;
        color: @gray-lighter;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: @screen-md-min) {
            font-size: 33px;
        }
        @media (min-width: @screen-sm-min) {
            font-size: 25px;
        }
    }

    .triangle {
        display: inline-block;
        float: left;
        margin: 10px;
        border-right: 5px solid;
        border-bottom: 5px solid;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        border-radius: 1px;
        @media (min-width: @screen-md-min) {
            border-right: 6px solid;
            border-bottom: 6px solid;
            width: 20px;
            height: 20px;
        }
    }

    .region-content-wrapper {
        display: none;
    }

    .region-wrapper:target {
        .region-content-wrapper {
            display:block;
        }
        .region-link {
            background-color: @brand-warning;
            .triangle {
                transform: rotate(225deg);
                margin-top: 25px;
            }
        }
    }

    .region-links-intro-text {
        font-size: 30px;
        font-weight: bold;
        color: @brand-primary;
        text-align: center;
        padding-bottom: 5px;
        padding-top: 30px
    }

    h2 {
        border-left: 15px solid @brand-info;
        font-size: 36px;
        font-weight: 400;
        padding: 15px;
        background-color: @light-gray-bg;
    }

    .distribution-area {
        margin: 40px 0;
        // hide the image and description for small screens
        .image-wrapper, .description {
            padding: 0;
            display: none;
            text-align: center;
            @media(min-width: @screen-md-min) {
                display: block;
            }
            img {
                width: 400px;
            }
        }
        .arrow {
            border-right: 15px solid @light-gray-bg;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            margin-top: 75%;
            position: absolute;
            width: 100%;
        }
        .region-info-box {
            padding: 0;
            background-color: @light-gray-bg;
            text-align: center;
            h3 {
                margin: 0;
                padding: 20px 0 10px 0;
                font-size: 30px;
                color: @brand-info;
            }
            .circulation-amount {
                color: @brand-danger;
                font-size: 90px;
                font-weight: 900;
            }
            .around-the-amount {
                font-size: @font-size-huge;
                position: relative;
                margin-bottom: 0;
                &.upper {
                    top: 20px;
                }
                &.lower {
                    top: -30px;
                }
            }
            button {
                color: @brand-danger;
                background: none;
                border: none;
                margin: 0;
                padding: 0;
            }
            .modal {
                text-align: left;
            }
            .media-kit-link:hover {
                text-decoration: none;
            }
            .media-kit {
                margin: 30px;
                background-color: @brand-info;
                color: white;
                font-size: 25px;
                font-weight: bold;
                padding: 15px;
                border-radius: 3px;
            }
            .salesperson-box {
                color: white;
                margin: 30px 0;
                @media(min-width: @screen-xs-min) {
                    margin: 30px 30px;
                }
                padding-bottom: 15px;
                background-color: @brand-primary;
                p {
                    padding: 0;
                    margin: 0;
                }
                a {
                    color: white;
                    text-decoration: none;
                }
                .header {
                    font-size: 30px;
                    font-weight: bold;
                    padding: 15px 30px;
                    @media(min-width: @screen-sm-min) {
                        padding: 15px 60px;
                    }
                    @media(min-width: @screen-lg-min) {
                        padding: 15px 130px;
                    }
                    background-color: @brand-danger;
                }
                .salesperson-names {
                    margin: 15px 0;
                    font-size: 30px;
                }
                .button {
                    border-radius: 3px;
                    background-color: @brand-info;
                    margin: 10px 20px;
                }
                .phone-button {
                    padding: 15px 0;
                    font-size: 35px;
                    @media(min-width: @screen-sm-min) {
                        font-size: 45px;
                    }
                    font-weight: bold;
                }
                .email-button {
                    padding: 10px 0;
                    margin-bottom: 20px;
                }
                .underliner {
                    border-bottom: 1px solid @gray-light;
                }
            }
        }
    }

    .adsizes {
        h3 {
            color: @brand-info;
            font-size: 26px;
            padding: 15px;
        }
        .adsize {
            border: 15px solid white;
        }
        .adsize-inner-wrap {
            background-color: @light-gray-bg;
        }
        .image-wrapper {
            text-align: center;
            @media (min-width: @screen-lg-min) {
                img {
                    height: 340px;
                    width: 460px;
                }
            }
            img {
                height: 306px;
                width: 414px;
            }
        }
        .price-table {
            text-align: center;
            color: @light-gray-bg;
            margin: 20px 25px;
            div {
                padding: 5px;
            }
            .price {
                padding: 0;
            }
            .price-name {
                font-size: 16px;
                border-bottom: 1px solid @light-gray-bg;
                font-weight: bold;
            }
            .price-value {
                font-size: 18px;
                font-weight: lighter;
            }
            .price-num-1 {
                background-color: @brand-primary;
            }
            .price-num-2 {
                background-color: @brand-info;
            }
            .price-num-3 {
                background-color: darken(@brand-info, 10%);
            }
            .price-num-4 {
                background-color: darken(@brand-info, 20%);
            }
        }
    }

    .technical {
        h3 {
            margin-bottom: 0;
            color: @brand-info;
        }
        .value-only.last {
            padding-top: 10px;
        }
        .email-name {
            float: left;
            padding-left: 15px;
        }
        .email {
            color: @brand-danger;
            padding-top: 15px;
        }
        .email-row.not-first {
            padding-top: 15px;
        }

        .publication-dates {
            h3 {
                padding-left: 15px;
            }
            .date-column {
                padding-right: 0;
            }
        }
    }
    .quotes {
        .quote {
            text-align: center;
            padding: 55px;
            .quote-text {
                font-size: 28px;
                font-style: italic;
                font-weight: bold;
                color: @brand-info;
                &::before {
                    content: "“";
                }
            }
            .quote-name {
                font-size: 25px;
                &::before {
                    content: "- ";
                }
            }
        }
    }

    .final-call-to-action {
        text-align: center;
        padding-bottom: 50px;
        h3 {
            font-size: 30px;
            color: @brand-primary;
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    .sharp-buttons {
        text-decoration: none;
        text-align: center;
        font-size: 16px;
        .contact-button {
            margin: 10px;
            border-radius: 5px;
        }
        .contact-button-wrapper {
            margin-top: 0;
            margin-bottom: 15px;
        }
        @media (min-width: @screen-sm-min) {
            font-size: 22px;
        }
    }
}

body.contacts {
    .body-wrapper {
        .contact-list-plugin {
            dt {
                color: #eb008c;
                font-size: 15px;
                text-transform: uppercase;
            }

            dd {
                margin-bottom: 10px;
            }

            ul {
                list-style: none;
                padding-left: 0;
            }

            li, a {
                color: #000;
                text-decoration: none;
            }

            .name {
                color: #113378;
                font-family: "Salsa", cursive;
                font-size: 20px;
            }

            .phone a:before {
                content: "\f095";
                font-family: "FontAwesome";
                display: inline-block;
                margin-right: 5px;
            }

            .email a:before {
                content: "\f0e0";
                font-family: "FontAwesome";
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}

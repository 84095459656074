.cmsplugin-classified-ad-list {
    .content {
        background: @light-gray-bg;
        padding: 20px 0;
    }

    .tab-pane {
        ul {
            list-style: none;
            margin: 0 auto;
            padding: 0;
            width: 85%;

            li {
                border-bottom: 1px solid darken(@border-color, 5%);
                padding: 10px 0;
                
                a {
                    color: #000;
                }
                
                a:hover {
                    text-decoration: none;
                }

                p {
                    margin-bottom: 0;
                }

                .price {
                    color: @brand-danger;
                }
            }
        }
    }

    .display-more-link {
        color: @brand-danger;
        display: block;
        margin: 0 auto;
        padding: 10px 0 5px 0;
        width: 85%;
    }
}
